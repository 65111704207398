// Initialise dev mode state from cookie
export const useDevModeActive = () => useState<Boolean>('wetix-dev-mode-active', () => useCookie<Boolean>('wetix-dev-mode'))

// useDevMode tools
export const useDevMode = () => {
    const { $notify } = useNuxtApp()

    return {
        active: useDevModeActive(),
        isEnabled: useDevModeActive().value,
        test: () => {
            console.log('test')
        },
        toggle: () => {
            console.log('Toggling dev mode')
            try {
                const cookie = useCookie<Boolean>('wetix-dev-mode')
                const state = useState<Boolean>('wetix-dev-mode-active')
                state.value = !state.value

                cookie.value = state.value
                $notify({
                    type: "success",
                    title: `Dev mode ${state.value ? 'enabled' : 'disabled'}`,
                })

            } catch (error) {
                $notify({
                    type: "error",
                    title: "Error toggling dev mode",
                    message: error.message || null,
                })
            }
        }
    }
}

